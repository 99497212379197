<template>

  <div class="p-d-flex p-flex-column container">

    <div id="d1" style="display:none;z-index: 1000;position: relative;">
      <ModalAlwaysShow 
        tipo='error'
        :messageErr="internalizationMyCons.myConsTimeOutLabel"
        :detailErr="internalizationMyCons.myConsTimeOutMsg"
      />
    </div>

    <TextBox :text="internalizationMyCons.myConsMsg"/>

    <TitleP
      :title="internalizationMyCons.myConsTitle"
      class="titleMyConsents"
    />

    <div class="filterTitle filterClientBox">

      <span
        class="filterTitle filterClientFstUpTitle"
      >
        {{internalizationMyCons.filterClientIssuerAccountTitle}}
      </span>
      <label class="LabelIssuerAccount">{{clientAgencySelected}} {{clientAccountSelected}} </label>

      <span v-if='allAccounts.length > 1' class="filterTitle filterClientFstUpTitle p-mt-4">{{internalizationMyCons.filterClientIssuerAccountSelector}}</span>
      <Dropdown
        v-if='allAccounts.length > 1'
        class="SelectAccountsClass"
        v-model="dropDownAccountSelected"
        :options="allAccounts"
        optionLabel="name"
        optionValue="code"
        placeholder="Selecione"
        @change="changeAccountSelected"
      />

      <div v-bind:class="{'space-solo-account': allAccounts.length === 1}" v-if="!showFilterClient">
        <span>{{internalizationMyCons.filterClientSeeAllFiltersTitle}}</span>
        <div class="miniBox" @click="changeShowFilterClient()">
          <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path :fill="fillFilterIconColor" fill-rule="evenodd" clip-rule="evenodd" d="M1.625 0.625C1.17627 0.625 0.8125 0.988769 0.8125 1.4375C0.8125 1.88623 1.17627 2.25 1.625 2.25H11.375C11.8237 2.25 12.1875 1.88623 12.1875 1.4375C12.1875 0.988769 11.8237 0.625 11.375 0.625H1.625ZM1.625 6.3125C1.17627 6.3125 0.8125 5.94873 0.8125 5.5C0.8125 5.05127 1.17627 4.6875 1.625 4.6875H11.375C11.8237 4.6875 12.1875 5.05127 12.1875 5.5C12.1875 5.94873 11.8237 6.3125 11.375 6.3125H1.625ZM1.625 10.375C1.17627 10.375 0.8125 10.0112 0.8125 9.5625C0.8125 9.11377 1.17627 8.75 1.625 8.75H11.375C11.8237 8.75 12.1875 9.11377 12.1875 9.5625C12.1875 10.0112 11.8237 10.375 11.375 10.375H1.625Z"/>
          </svg>
        </div>
      </div>

      <div v-if="showFilterClient">

        <span class="filterTitle filterClientFstUpTitle">{{internalizationMyCons.filterClientStatusConsultTitle}}</span>
        <div class="filterClientCheck">
          <Checkbox id="awaitingCheck" class="checkP" v-model="awaitingCheck" :binary="true" @click="setStatusBool('AWAITING_AUTHORISATION', !awaitingCheck, true)"/>
          <label class="filterLabel" for="awaitingCheck">{{internalizationMyCons.filterClientStatusAwaitingLabel}}</label>
        </div>
        <div class="filterClientCheck">
          <Checkbox id="authorizedCheck" class="checkP" v-model="authorizedCheck" :binary="true" @click="setStatusBool('AUTHORISED', !authorizedCheck, true)"/>
          <label class="filterLabel" for="authorizedCheck">{{internalizationMyCons.filterClientStatusAuthorizedLabel}}</label>
        </div>
        <div class="filterClientCheck">
          <Checkbox id="consumedCheck" class="checkP" v-model="consumedCheck" :binary="true" @click="setStatusBool('CONSUMED', !consumedCheck, true)"/>
          <label class="filterLabel" for="consumedCheck">{{internalizationMyCons.filterClientStatusConsumedLabel}}</label>
        </div>
        <div class="filterClientCheck">
          <Checkbox id="rejectedCheck" class="checkP" v-model="rejectedCheck" :binary="true" @click="setStatusBool('REJECTED', !rejectedCheck, true)"/>
          <label class="filterLabel" for="rejectedCheck">{{internalizationMyCons.filterClientStatusRejectedLabel}}</label>
        </div>

        <span class="filterTitle filterClientUpTitle">{{internalizationMyCons.filterClientPeriodConsultTitle}}</span>
        
        <div class="filterCRDiv">
          <RadioButton id="7days" class="radioP" v-model="daysSelected" value="7" :required="false" @click="setDaysSelected('7', true)"/>
          <label for="7days" class="filterLabel filterCRLabel">{{internalizationMyCons.filterClientPeriod7DaysLabel}}</label>

          <RadioButton id="15days" class="radioP" v-model="daysSelected" value="15" :required="false" @click="setDaysSelected('15', true)"/>
          <label for="15days" class="filterLabel filterCRLabel">{{internalizationMyCons.filterClientPeriod15DaysLabel}}</label>

          <RadioButton id="30days" class="radioP" v-model="daysSelected" value="30" :required="false" @click="setDaysSelected('30', true)"/>
          <label for="30days" class="filterLabel filterCRLabel">{{internalizationMyCons.filterClientPeriod30DaysLabel}}</label>

          <RadioButton id="90days" class="radioP" v-model="daysSelected" value="90" :required="false" @click="setDaysSelected('90', true)"/>
          <label for="90days" class="filterLabel filterCRLabel">{{internalizationMyCons.filterClientPeriod90DaysLabel}}</label>
        
          <RadioButton id="dinDays" class="radioP" v-model="daysSelected" value="?" :required="false" @click="setDaysSelected('?', false)"/>
          <label for="dinDays" class="filterLabel filterCRLabel">{{internalizationMyCons.filterClientPeriodDinamycDaysLabel}}</label>
        </div>

        <span class="filterTitle filterClientUpTitle">{{internalizationMyCons.filterClientTypeConsultTitle}}</span>
        
        <div class="filterCRDiv">
          <RadioButton
            id="allConsents"
            class="radioP"
            v-model="typeConsentSelected"
            value="all"
            :required="false"
            @click="selectTypeConsent('all')"
          />
          <label for="allConsents" class="filterLabel filterCRLabel">
            {{internalizationMyCons.filterClientFilteredTypeAllLabel}}
          </label>

          <RadioButton
            id="scheduledConsents"
            class="radioP"
            v-model="typeConsentSelected"
            value="scheduled"
            :required="false"
            @click="selectTypeConsent('scheduled')"
          />
          <label for="scheduledConsents" class="filterLabel filterCRLabel">
            {{internalizationMyCons.filterClientFilteredTypeScheduledLabel}}
          </label>
        </div>

        <div v-if="awaitingCheck || authorizedCheck || rejectedCheck || consumedCheck || (formatStartFilterDate != '' && formatEndFilterDate != '')">
          <hr style="width: 100%; margin-top: 22px;"/>
        </div>

        <div class="filteredStatus" v-if="awaitingCheck || authorizedCheck || rejectedCheck || consumedCheck">

          <div class="filteredTitle">{{internalizationMyCons.filterClientFilteredStatusTitle}}</div>

          <div class="filteredBoxDiv">
            <span v-if="awaitingCheck" class="filteredLabel filteredBox"><strong>{{internalizationMyCons.filterClientFilteredStatusAwaitingLabel}}</strong><i class="pi pi-times" @click="clearFilter('awaitingCheck')"></i></span>
            <span v-if="authorizedCheck" class="filteredLabel filteredBox"><strong>{{internalizationMyCons.filterClientFilteredStatusAuthorizedLabel}}</strong><i class="pi pi-times" @click="clearFilter('authorizedCheck')"></i></span>
            <span v-if="rejectedCheck" class="filteredLabel filteredBox"><strong>{{internalizationMyCons.filterClientFilteredStatusRejectedLabel}}</strong><i class="pi pi-times" @click="clearFilter('rejectedCheck')"></i></span>
            <span v-if="consumedCheck" class="filteredLabel filteredBox"><strong>{{internalizationMyCons.filterClientFilteredStatusConsumedLabel}}</strong><i class="pi pi-times" @click="clearFilter('consumedCheck')"></i></span>
          </div>

        </div>

        <div class="filteredPeriod" v-if="formatStartFilterDate != '' && formatEndFilterDate != ''">

          <div class="filteredTitle">{{internalizationMyCons.filterClientFilteredPeriodTitle}}</div>

          <div class="filteredBoxDiv">
            <span class="filteredLabel filteredBox">
              {{internalizationMyCons.filterClientFilteredPeriodLabel1}} <strong>{{formatStartFilterDate}}</strong> {{internalizationMyCons.filterClientFilteredPeriodLabel2}} <strong>{{formatEndFilterDate}}</strong>
              <i class="pi pi-times" @click="clearFilter('period')"></i>
            </span>
          </div>

        </div>

        <div class="filteredPeriod">

          <div class="filteredTitle">{{internalizationMyCons.filterClientFilteredTypeTitle}}</div>

          <div class="filteredBoxDiv">
            <span class="filteredLabel filteredBox">
              <strong v-if='typeConsentSelected === "all"'>{{internalizationMyCons.filterClientFilteredTypeAllLabel}}</strong>
              <strong v-else>{{internalizationMyCons.filterClientFilteredTypeScheduledLabel}}</strong>
              <!-- <i class="pi pi-times" @click="clearFilter('period')"></i> -->
            </span>
          </div>

        </div>

        <div class="filteredClear" v-if="awaitingCheck || authorizedCheck || rejectedCheck || (formatStartFilterDate != '' && formatEndFilterDate != '')">
          
          <div class="filteredBoxDiv">
            <span class="filteredLabelClear filteredBox">
              {{internalizationMyCons.filterClientCleanFilterLabel}}
              <i class="pi pi-times" @click="clearFilter('all')"></i>
            </span>
          </div>

        </div>

      </div>

    </div>

    <div style="padding-left: 2em; padding-right: 2em;"><hr style="width: 100%"/></div>

    <div v-for="item in data" :key="item.itemId">
      <ConsentStatusCard 
        :detailView="item.detailView"
        :id="item.itemId"
        :cardStatus="item.cardStatus"
        :cardStatusTitle="item.cardStatusTitle"
        :cardStatusDescription="item.cardStatusDescription"
        :cardInfoTitle="item.beneficiaryname"
        :cardInfoDescription="item.value"
      />
    </div>

    <div v-if="data != null && data != [] && data.length > 0" style="text-align: center; margin-top: 15px;">
      <div class="paginatorWrapper">
        
        <div class="paginatorContent paginatorLeft" v-if="actualPage > 1" @click="getPreviousPage()">
          <svg class="paginatorIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path :fill="fillPaginatorIconsColor" fill-rule="evenodd" clip-rule="evenodd" d="M8.14645 11.6465L14.1464 5.64648L15 6.00004V18L14.1464 18.3536L8.14645 12.3536V11.6465Z"/>
          </svg>
        </div>

        <div class="paginatorContent paginatorRight" v-if="actualPage < maxPages" @click="getNextPage()">
          <svg class="paginatorIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path :fill="fillPaginatorIconsColor" fill-rule="evenodd" clip-rule="evenodd" d="M16.3536 11.6465L10.3536 5.64648L9.5 6.00004V18L10.3536 18.3536L16.3536 12.3536V11.6465Z"/>
          </svg>
        </div>

        <div class="paginatorContent paginatorCenter">
          <span class="paginatorText">
            <span class="paginatorLeftText">{{actualPage}}</span> <span class="paginatorCenterText">/</span> <span class="paginatorRightText">{{maxPages}}</span>
          </span>
        </div>

      </div>
    </div>
    
  </div>

  <ConfirmModal
    ref="confirmModal"
    :messageErr="internalizationMyCons.myConsWarningModalLabel"
    :detailErr="internalizationMyCons.myConsWarningModalMsg"
    :detailErr2="internalizationMyCons.myConsSubWarningModalMsg"
  />
  <ExceptionModal
    ref="exceptionWarnig"
    tipo="error"
    :messageErr="internalizationMyCons.myConsErrCommunicationFailLabel"
    :detailErr="internalizationMyCons.myConsErrCommunicationFailMsg"
  />
  <ExceptionModal
    ref="exceptionNoConsent"
    tipo="info"
    messageErr=""
    :detailErr="internalizationMyCons.myConsNoConsModalMsg"
    :showOkButton="true"
    @okButtonAction="exceptionNoConsentDisable()"
  />

  <Footer 
    :footerText2="internalizationMyCons.myConsContactUsLabel" 
    :action1="action2"
  />

  <FilterCalendarModal 
    ref="calendarModal"
    @action="setDinamycDays()"
  />

</template>

<script>

const dayInMs = 86400000;
const consentPagePaginatorSize = 10;

/* timeout controllers */
const FETCH_TIMEOUT = 30000;
var didTimeOut = false;
var timeoutActiveTokens = [];
/* */

var holder = null;

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

import
{
  SessionObjects,
  AccountTypes,
  ConsentStatus,
  GetConsentStatus,
  GetAccountType,
  GetConsentCssClass,
  getInternalization,
  MaskCpfCnpj,
  getWithExpiry,
  endSess
} from '@/util/util.js';

export default {
  data() {
    return {
      clientAccountSelected: '',
      clientAgencySelected: '',
      allAccounts: [],
      iconMeusCompartilhamentos: 'meus-compartilhamentos',
      iconMeusConsentimentos: 'meus-consentimentos',
      aboutRoute: 'MyConsents',
      data: [],
      onLine:true,
      showInit: true,

      showFilterClient: false,
      dropDownAccountSelected: '',
      awaitingCheck: true,
      authorizedCheck: true,
      rejectedCheck: true,
      consumedCheck: true,
      daysSelected: '',
      startFilterDate: '',
      endFilterDate: '',
      formatStartFilterDate: '',
      formatEndFilterDate: '',

      paginationTotalRecords: 0,
      actualPage: 1,
      maxPages: 1,
      idAccount: null,
      typeConsentSelected: 'all',

      fillFilterIconColor: 'var(--filterclient-allfiltersicon-color)',
      fillPaginatorIconsColor: 'var(--consultconsent-paginator-icons-color)'
    }
  },
  methods: {

    changeAccountSelected () {
      let statusList = [];
      if (this.awaitingCheck)
        statusList.push('AWAITING_AUTHORISATION');
      if (this.authorizedCheck)
        statusList.push('AUTHORISED');
      if (this.rejectedCheck)
        statusList.push('REJECTED');
      if (this.consumedCheck)
        statusList.push('CONSUMED');

      this.clientAgencySelected = holder.accounts[this.dropDownAccountSelected].account_agency;
      this.clientAccountSelected = holder.accounts[this.dropDownAccountSelected].account_number;
      this.idAccount = [holder.accounts[this.dropDownAccountSelected].account_id];
      this.setConsentFilterArray(statusList, this.startFilterDate, this.endFilterDate, this.pageNumber);
    },

    init() {

      let numberAccount = 0
      holder.accounts.forEach(account => {
        const data = {
          name: `${account.account_agency} ${account.account_number}`,
          code: numberAccount
        }
        this.allAccounts.push(data)
        numberAccount++
      })

      var backFromDetail = sessionStorage.getItem(SessionObjects.BACK_TO_CONSENT_LIST);
      if(backFromDetail != null) {
        sessionStorage.removeItem(SessionObjects.BACK_TO_CONSENT_LIST);
        return;
      } 
      else {
        this.$refs.confirmModal.enable();
      }
    },
    async timeoutFunction(tokenPos) {
      await sleep(FETCH_TIMEOUT);
      if (timeoutActiveTokens[tokenPos]){
        didTimeOut = true;
        return 'Request timed out';
      }
      return 'Not Timed out';
    },
    async oldFetchGetConsents() {

      let userId = holder.sub;

      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      try{

        if(sessionStorage.getItem('TIMEOUT_SIMULATION_CONSULT_CONSENTS') === 'true'){
          console.log('TIMEOUT_SIMULATION_CONSULT_CONSENTS');
          await sleep(FETCH_TIMEOUT);
        }

        if(didTimeOut)
          throw 'Request timed out';
        let response = await fetch(`${process.env.VUE_APP_CONSENTS}/consent/consent-list/${userId}`, requestOptions);

        if(!response.ok)
          throw 'consent list status error';

        if(didTimeOut)
          throw 'Request timed out';
        let consents = await response.json();

        return consents;
      }
      catch(error){
        console.log(error);
        return 'consent list error';
      }
    },
    async fetchGetConsentsFilter(ifId, consentStatus, dateInit, dateEnd, pageNumber) {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "ifId": ifId,
        "consentStatus": consentStatus,
        "dateInit": dateInit,
        "dateEnd": dateEnd,
        "pageSize": consentPagePaginatorSize,
        "pageNumber": pageNumber,
        "accounts": this.idAccount,
        "scheduled": this.typeConsentSelected === 'all' ? false : true
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      try{

        if(sessionStorage.getItem('TIMEOUT_SIMULATION_CONSULT_CONSENTS') === 'true'){
          console.log('TIMEOUT_SIMULATION_CONSULT_CONSENTS');
          await sleep(FETCH_TIMEOUT);
        }

        if(didTimeOut)
          throw 'Request timed out';
        let response = await fetch(`${process.env.VUE_APP_CONSENTS}/consent/search-client`, requestOptions);

        //console.log(response);

        if(!response.ok || response.errorMsg != null)
          throw 'consent filter status error';

        if(didTimeOut)
          throw 'Request timed out';
        let consents = await response.json();

        if (consents.data == null)
          return [];

        return consents;
      }
      catch(error){
        console.log(error);
        return 'consent filter error';
      }
    },
    // AWAITING_AUTHORISATION, REJECTED, AUTHORISED
    async setConsentFilterArray(listConsentStatus, dateInit, dateEnd, pageNumber){

      let ifId = process.env.VUE_APP_IF_NAME.toLowerCase().trim();
      if (ifId == 'qa')
        ifId = 'exb';

      let dateInitFormated = (dateInit != null ? this.getFormatedDateyyyymmdd((new Date(dateInit.getTime())), '-') : '');
      let dateEndFormated = (dateEnd != null ? this.getFormatedDateyyyymmdd((new Date(dateEnd.getTime())), '-') : '');
      let paginationTotalRecordsTmp = 0;

      try{
        this.$root.$refs.loadingModal.enable();

        this.data = [];

        if (listConsentStatus == null || listConsentStatus == [] || listConsentStatus.length == 0){
          listConsentStatus = ['AWAITING_AUTHORISATION', 'REJECTED', 'AUTHORISED', 'CONSUMED'];
        }

        timeoutActiveTokens.push(true);
        let tokenPos = timeoutActiveTokens.length - 1;
        let consentsResponse = await Promise.race([
          this.timeoutFunction(tokenPos),
          this.fetchGetConsentsFilter(ifId, listConsentStatus, dateInitFormated, dateEndFormated, pageNumber)
        ]);
        if(consentsResponse == 'Request timed out')
          throw consentsResponse;
        timeoutActiveTokens[tokenPos] = false;

        if (consentsResponse == 'consent filter error')
          throw consentsResponse;

        if (consentsResponse != null && consentsResponse != [] && consentsResponse.length != 0){

          //console.log(consentsResponse);

          paginationTotalRecordsTmp = consentsResponse.totalRecords;

          let consentArray = Array.from(consentsResponse.data);

          consentArray.forEach( (c) => {

            if(c.consentBody.data.brandID == undefined){
              let tmp = {
                itemId: "",
                detailView: "DetailConsent",
                cardStatus: "",
                infoMessage: "",
                cardStatusTitle: "",
                cardStatusDescription: "",
                payerCpf: "",
                payerName: "",
                payerCc: "",
                beneficiaryCnpj: "",
                beneficiaryname:"",
                paymentIniciationData: "",
                value:"",
                formOfPayment:"",
                order:0,
                lastUpdateDate: null
              }
            
              var payment = c.consentBody.data.payment;
              var creditor = c.consentBody.data.creditor;
              var parsedStatus = c.consentBody.data.status;

              tmp.itemId = c.consentBody.data.consentId;
              tmp.cardStatus = this.parseStatus(parsedStatus);
              tmp.cardStatusTitle = this.parseTitle(parsedStatus);
              tmp.cardStatusDescription = this.parseConsentDate(parsedStatus, c.consentBody.data, 'pt-BR');
              tmp.value = this.parseCurrency('pt-BR', payment.currency, payment.amount);
              tmp.beneficiaryname = creditor.name;
              tmp.order = this.getOrder(parsedStatus);
              tmp.expirationDateTime = new Date(c.consentBody.data.expirationDateTime);
              tmp.lastUpdateDate = new Date(c.consentBody.data.statusUpdateDateTime);
              tmp.creationDateTime = c.consentBody.data.creationDateTime;

              let contains = false;
              for(let j = 0; j < this.data.length; j++){
                if ((this.data[j]).itemId == tmp.itemId){
                  contains = true;
                  break;
                }
              }
              if (!contains){
                let obj = JSON.parse(JSON.stringify(tmp));
                this.data.push(obj);
              }
            }
          });
        }

        if(this.data.length <= 0){
          this.$root.$refs.loadingModal.disable();
          this.showInit = false;
          this.showNoConsModal();
        }
        else{
          this.paginationTotalRecords = paginationTotalRecordsTmp;
          this.updatePageNumbers(pageNumber, Math.ceil(this.paginationTotalRecords/consentPagePaginatorSize));
        }

        this.$root.$refs.loadingModal.disable();
      }
      catch(error){
        console.log(error);
        this.$root.$refs.loadingModal.disable();

        if (error == 'Request timed out'){
          var el = document.getElementById("d1");
          el.style.display = "flex";
        }
        else{
          this.showWarningModal();
        }
      }
    },
    setStatusBool(status, value, doFilter){

      if(status == 'AWAITING_AUTHORISATION')
        this.awaitingCheck = value;

      else if(status == 'AUTHORISED')
        this.authorizedCheck = value;

      else if(status == 'REJECTED')
        this.rejectedCheck = value;

      else if(status === 'CONSUMED')
        this.consumedCheck = value;

      if (doFilter)
        this.doFilterConsents(1);
    },

    selectTypeConsent(type) {
      this.typeConsentSelected = type

      this.doFilterConsents(1);
    },

    setDaysSelected(value, doFilter){

      if (value != '?' && value != ''){
        this.daysSelected = value;

        this.endFilterDate = new Date((new Date()).getTime() + dayInMs);
        this.startFilterDate = new Date(this.endFilterDate.getTime() - (value*dayInMs));
        this.endFilterDate.setHours(0,0,0,0);
        this.startFilterDate.setHours(0,0,0,0);

        this.formatStartFilterDate = this.getFormatedDateddmmyyyy(this.startFilterDate);
        this.formatEndFilterDate = this.getFormatedDateddmmyyyy(this.endFilterDate);
      }
      else if (value == '?'){
        this.daysSelected = '?';
        this.$refs.calendarModal.enable();
      }

      if (doFilter)
        this.doFilterConsents(1);
    },

    async doFilterConsents(pageNumber){

      let dateStartDef = this.startFilterDate;
      let dateEndDef = this.endFilterDate;

      let statusList = [];
      if (this.awaitingCheck)
        statusList.push('AWAITING_AUTHORISATION');
      if (this.authorizedCheck)
        statusList.push('AUTHORISED');
      if (this.rejectedCheck)
        statusList.push('REJECTED');
      if (this.consumedCheck)
        statusList.push('CONSUMED');

      await this.setConsentFilterArray(statusList, dateStartDef, dateEndDef, pageNumber);
    },

    updatePageNumbers(actualPage, maxPages){
      this.actualPage = actualPage;
      this.maxPages = maxPages;
    },

    getPreviousPage(){
      if(this.actualPage > 1){
        
        let newActualPage = this.actualPage - 1;
        this.updatePageNumbers(newActualPage, this.maxPages);
        this.doFilterConsents(newActualPage);

      }
      else
        console.log('error, minimun page reached!')
    },

    getNextPage(){
      if(this.actualPage < this.maxPages){

        let newActualPage = this.actualPage + 1
        this.updatePageNumbers(newActualPage, this.maxPages);
        this.doFilterConsents(newActualPage);

      }
      else
        console.log('error, maximum page reached!');
    },
    showWarningModal(){
      this.$refs.exceptionWarnig.enable();
    },
    showNoConsModal(){
      this.$refs.exceptionNoConsent.enable();
    },
    exceptionNoConsentDisable(){
      this.$refs.exceptionNoConsent.disable();
    },
    redirectToConsultConsents(){
      this.$router.push({ name: 'ConsultConsents' });
    },
    checkOnline(){
      if(!navigator.onLine){
        endSess('dados');
        this.$refs.exceptionWarnig.enable();
        this.$root.$refs.loadingModal.disable();
      }
    },
    defTimeOut(){
      var timeout;
      function refresh(){
        clearTimeout(timeout);

        timeout = setTimeout(() => {
          var el = document.getElementById("d1");
          el.style.display = "flex";
        },  300000);
      }
      refresh();
      document.addEventListener('click', refresh);
    },
    parseStatus(status) {
      return GetConsentCssClass(status);
    },
    parseTitle(status) {
      return GetConsentStatus(status);
    },
    convertUtcDateToString(date, locale, isDateTime){
      var tmp = new Date(date);
      return isDateTime 
        ? tmp.toLocaleString(locale) 
        : tmp.toLocaleDateString(locale);
    },
    getFormatedDateddmmyyyy(date){
      return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + ('000' + date.getFullYear()).slice(-4);
    },
    getFormatedDateyyyymmdd(date, separatorChar){
      return ('000' + date.getFullYear()).slice(-4) + separatorChar + ('0' + (date.getMonth()+1)).slice(-2) + separatorChar + ('0' + date.getDate()).slice(-2);
    },
    parseConsentDate(status, consentBody, locale) {
      switch(status) {
        case ConsentStatus.ACCEPTED : return `${this.convertUtcDateToString(consentBody.statusUpdateDateTime, locale, true).replace(' ',' - ')}`;
        case ConsentStatus.REJECTED:  return `${this.convertUtcDateToString(consentBody.statusUpdateDateTime, locale, true).replace(' ',' - ')}`;
        case ConsentStatus.WAITING:   return `${this.internalizationMyCons.myConsAndDetailConsUpBoxInnerLabelWaiting} ${this.convertUtcDateToString(consentBody.expirationDateTime, locale, true).replace(' ',' - ')}`;
        default:return this.convertUtcDateToString(consentBody.statusUpdateDateTime, locale, true);
      }
    },
    parseCurrency(locale, type, value) {
      var formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: type,
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
      })
      return formatter.format(value);
    },
    getOrder(status) {
      switch(status) {
        case ConsentStatus.ACCEPTED : return 1;
        case ConsentStatus.REJECTED:  return 0;
        case ConsentStatus.WAITING:   return 2;
        default: return 0;
      }
    },
    changeShowFilterClient(){
      this.showFilterClient = !this.showFilterClient;
    },
    clearFilter(filter){

      if (filter == 'all'){
        this.awaitingCheck = false;
        this.authorizedCheck = false;
        this.rejectedCheck = false;
        this.consumedCheck = false;
        this.data = [];
        this.daysSelected = '';
        this.startFilterDate = null;
        this.endFilterDate = null;
        this.formatStartFilterDate = '';
        this.formatEndFilterDate = '';
        return
      }
      else if(filter == 'awaitingCheck'){
        this.awaitingCheck = false;
      }
      else if(filter == 'authorizedCheck'){
        this.authorizedCheck = false;
      }
      else if(filter == 'rejectedCheck'){
        this.rejectedCheck = false;
      }
      else if(filter == 'consumedCheck'){
        this.consumedCheck = false;
      }
      else if(filter == 'period'){
        this.daysSelected = '';
        this.startFilterDate = null;
        this.endFilterDate = null;
        this.formatStartFilterDate = '';
        this.formatEndFilterDate = '';
      }

      this.doFilterConsents(1);
    },
    setDinamycDays(){
      this.startFilterDate = this.$refs.calendarModal.dateModelStart;
      this.endFilterDate = this.$refs.calendarModal.dateModelEnd;
      this.startFilterDate.setHours(0,0,0,0);
      this.endFilterDate.setHours(0,0,0,0);

      this.formatStartFilterDate = this.getFormatedDateddmmyyyy(this.startFilterDate);
      this.formatEndFilterDate = this.getFormatedDateddmmyyyy(this.endFilterDate);

      this.doFilterConsents(1);
    }
  },
  async created(){

    this.internalizationMyCons = getInternalization();
    
    this.defTimeOut();
    holder = JSON.parse(getWithExpiry('dados'));
    this.clientAccountSelected = holder.accounts[0].account_number;
    this.clientAgencySelected = holder.accounts[0].account_agency;
    this.idAccount = [holder.accounts[0].account_id];
    
    this.$root.$refs.loadingModal.enable();

    if(!holder){
      this.$root.$refs.exceptionWarnigFetchingData.enable();
      this.$root.$refs.loadingModal.disable();
    }

    else{

      setInterval(() => {
        this.checkOnline();
      }, 3000);

      this.setDaysSelected('30',false);

      let dateEndDef = new Date((new Date()).getTime() + dayInMs);
      let dateStartDef = new Date(dateEndDef.getTime() - (30*dayInMs));
      dateEndDef.setHours(0,0,0,0);
      dateStartDef.setHours(0,0,0,0);

      await this.setConsentFilterArray(['AWAITING_AUTHORISATION', 'REJECTED', 'AUTHORISED', 'CONSUMED'], dateStartDef, dateEndDef, 1);

    }
  },
  mounted() {
    this.$root.$refs.mainNavbar.showGoBackButton();
    if(this.showInit){
      this.init();
    }
  }
}
</script>

<style scoped>

.container {
  background-color: var(--page-background-color);
  padding: 1em;
}
.card {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align:justify;
}
.filterClientBox{
  padding-left: 2em;
  padding-right: 2em;
}
.miniBox{
  display: inline;
  background-color: var(--home-card1-background-color);
  box-shadow: var(--shadow-default);
  padding: 5px 7px;
  box-sizing: border-box;
  border-radius: 15px;
  width: 17px;
  height: 17px;
  margin-left: 10px;
  cursor: pointer;
}
.filterClientUpTitle, .filterClientFstUpTitle{
  display: block;
  font-size: 20px;
  margin-top: 22px;
  margin-bottom: 20px;
}
.filterClientUpTitle, .filterClientFstUpTitle{
  font-size: 20px;
}
.filterTitle{
  color: var(--filterclient-filtertitle-color);
}
.filterLabel{
  color: var(--filterclient-filterlabel-color);
}
.filteredLabelClear{
  font-weight: bold;
  color: var(--filterclient-clearfilterlabel-color);
}
.filteredTitle{
  font-size: 16px;
  color: var(--filterclient-filteredtitle-color);
}
.filteredLabel{
  color: var(--filterclient-filteredlabel-color);
}
.filteredStatus, .filteredPeriod{
  margin-top: 22px;
  margin-bottom: 20px;
}
.filteredClear{
  margin-top: 44px;
  margin-bottom: 20px;
}
.filterClientFstUpTitle{
  margin-top: 10px;
}
.filterClientDownTitle{
  display: block;
  margin-top:12px;
  margin-bottom:7px;
}
.filterClientCheck, .filterCRDiv{
  font-size: 14px;
  margin-top:7px;
  margin-bottom:7px;
}
.filteredBoxDiv{
  margin-top: 20px;
}
.filteredBox {
  background-color: var(--home-card1-background-color);
  box-shadow: var(--shadow-default);
  font-size: 18px;
  padding: 10px 15px 10px 15px;
  border-radius: 15px;
  margin-right:10px;
}
.filteredBox .pi-times{
  margin-left:10px;
  font-size: 14px;
  cursor: pointer;
}
.radioP{
  margin-right:10px;
}
.filterCRLabel{
  margin-right:20px;
}
.checkP{
  margin-right:7px;
}
hr{
  background-color: var(--confcons-hr-color);
  border: 0;
  clear:both;
  display:block;
  height: 0.5px;
  margin-top:15px;
  margin-bottom: 15px;
}

.paginatorWrapper{
  width: 100%;
  margin: 0 auto;
  display: inline-block;
}
.paginatorContent{
  line-height: 24px;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
}
.paginatorLeft, .paginatorRight{
  width: 50px;
  cursor: pointer;
}
.paginatorLeft{
  float: left;
}
.paginatorRight{
  float: right;
}
.paginatorCenter{
  margin: 0 auto;
  width: 100px;
}
.paginatorIcon{
  vertical-align: middle;
}
.paginatorText{
  font-size: 18px;
}
.paginatorLeftText{
  color: var(--consultconsent-paginator-actualpage-text-color);
}
.paginatorCenterText{
  color: var(--consultconsent-paginator-text-color);
}
.paginatorRightText{
  color: var(--consultconsent-paginator-text-color);
}
.LabelIssuerAccount {
  color: #008AD1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.titleMyConsents {
  margin-top: -26px;
}

.SelectAccountsClass {
  margin-bottom: 24px;
  border-radius: 8px;
  width: 312px;
}

.pi-chevron-down {
  color: #008AD1 !important;
}

.space-solo-account {
  margin-top: 18px;
}
</style>